var blockedPage;

if (Function.prototype.name === undefined && Object.defineProperty !== undefined) {
    Object.defineProperty(Function.prototype, 'name', {
        get: function() {
            var funcNameRegex = /function\s([^(]{1,})\(/;
            var results = (funcNameRegex).exec((this).toString());
            return (results && results.length > 1) ? results[1].trim() : "";
        },
        set: function(value) {}
    });
}

(function($){

    blockedPage = {

        $body : null,
        $main : null,
        $header : null,
        $actual : null,
        sections : null,
        totalSections : null,
        percurso : null,
        width : null,
        height: null,
        actual : 1,
        locked : false,
        timeout : false,
        timeSetFree : false,
        transition : 800,
        pager : {
            $ : null,
            $items : null
        },

        init : function(){
            
            // Main elements
            this.$body = $( 'body' );
            this.$html = $( 'html' );
            this.$header = this.$body.children( 'header' ).first();

            if( window.innerWidth < 769 ){
                this.setFreeNavigation();
                return;
            }

            if( !this.$html.hasClass( 'blocked-page-nav' ) ) return true;

            this.$main = this.$body.children( 'main' ).first();
            this.width = this.$body.outerWidth();
            this.height = this.$body.outerHeight();
            this.sections = this.$main.children( 'section' );
            this.arrow = this.sections.first().find( '.seta' );
            this.total = this.sections.length;

            // Create pager
            this.createPager();
        
            // Check if hashed page
            this.checkHash();

            // Bind arrow
            this.bindArrow();

            // Desktop events
            window.addEventListener( 'wheel', ( event ) => {
                this.bindWhellEvent( event );
            });

            // Mobile Events 
            const _hammer = new Hammer( window );
            this.bindMobileEvents( _hammer );

            // Bind iframes
            const $iframes = $( 'iframe' );
            if( $iframes.length ){

                $iframes.each(function(){

                    this.contentDocument.addEventListener( 'wheel', ( event ) => {
                        blockedPage.bindWhellEvent( event );
                    });

                    const _hammer = new Hammer( this.contentDocument );
                    blockedPage.bindMobileEvents( _hammer );

                });
            
            }

            // Window location events
            window.addEventListener( 'popstate', ( e ) => {

                // Just window
                if( e.explicitOriginalTarget.location !== undefined ){

                    const section = this.sections.filter( '[data-id="' + window.location.hash.toString().substr( '1' ) + '"]' );
                    if( section.length ){

                        const indice = section.index() + 1;
                        this.moveTo( parseInt( indice ) );

                    }

                }
                
            });

        },

        setFreeNavigation : function(){

            this.$html.removeClass( 'blocked-page-nav' );
            this.$body.addClass( 'mobile-nav' );

            $( '.seta' ).on( 'click', function(e){

                e.preventDefault();

                const $section = blockedPage.$body.find( 'main > section' ).eq( 1 );
                window.scrollTo( 0, $section.offset().top );
            
            });

            $(window).scroll(function(){

                var top = ( window.pageYOffset || document.documentElement.scrollTop ) - (document.documentElement.clientTop || 0 );

                // Header class
                if( top < blockedPage.$header.outerHeight() ){
                    blockedPage.$header.removeClass( 'scrolled' );
                }
                else {
                    blockedPage.$header.addClass( 'scrolled' );
                }

                
            });
        
        },

        bindArrow : function(){

            if( this.arrow.length ){
            
                this.arrow.on( 'click', ( e ) => {
                
                    e.preventDefault();
                    this.moveTo( 2 );
                
                });
            
            }

        },

        bindWhellEvent : function( event ){
            if( this.locked === false ){
                // Swipe top
                if( event.deltaY >= 40 && event.deltaX === 0 ){
                    this.moveTo( 'top' );
                }
                else if( event.deltaY <= -40 && event.deltaX === 0 ){
                    this.moveTo( 'bottom' );
                }
            }
        },

        bindMobileEvents : function( instance ){
            var mc = new Hammer( window );
            mc.get( 'swipe' ).set({ direction: Hammer.DIRECTION_ALL });
            mc.get( 'pan' ).set({ direction: Hammer.DIRECTION_ALL });
            mc.on( 'pan swipe', ( event ) => {
                if( event.additionalEvent !== undefined && this.locked === false ){
                    if( event.additionalEvent === 'panup' || event.additionalEvent === 'swipeup' ){
                        this.moveTo( 'top' );
                    }
                    else if( event.additionalEvent === 'pandown' || event.additionalEvent === 'swipedown' ){
                        this.moveTo( 'bottom' );
                    }
                }
            });
        },

        createPager : function(){

            this.pager.$ = $( '<menu class="blocked-page-menu"></menu>' );
            this.sections.each(function( i ){
                this.$ = this.$ || $( this );
                $( '<li><a href="#'+ this.$.data( 'id' ) +'" title="Bloco '+ ( i + 1 ) +'"><strong>'+ this.title +'</strong></a></li>' ).appendTo( blockedPage.pager.$ );
            
            });
            this.pager.$.appendTo( this.$body );
            this.pager.$items = this.pager.$.find( 'a' );
            this.pager.$items.eq( this.actual - 1 ).addClass( 'active' );

            // Bind menu click
            this.pager.$items.on( 'click tap', function(e){
            
                e.preventDefault();

                this.$ = this.$ || $( this );

                const indice = this.$.parent( 'li' ).index() + 1;

                blockedPage.moveTo( parseInt( indice ) );
                
            });

            // Remove titles from sections
            this.sections.removeAttr( 'title' );

        },

        moveTo : function( direction, timetrans, setHash ){

            // Do nothing on limits
            if( ( direction == 'top' && this.actual === this.total ) || ( direction == 'bottom' && this.actual === 1 ) ){
                this.locked = false;
            }
            else {

                this.locked = true

                var newPage;
                if( typeof direction === 'number' ){
                    newPage = direction;
                }
                else {
                    newPage = direction == 'top' ? this.actual + 1 : this.actual - 1;
                }

                // Remove actual class
                this.sections.eq( this.actual ).removeClass( 'actual' ).addClass( 'going' );

                const 
                    newTop = ( ( ( newPage * 100 ) - 100 ) * -1 ),
                    timeTrans = ( timetrans ? timetrans : this.transition )
                ;
                this.$main.css({
                    '-webkit-will-change' : 'transform',
                    '-moz-will-change' : 'transform',
                    '-ms-will-change' : 'transform',
                    'will-change' : 'transform',
                    '-webkit-transform' : 'translate3d(0, ' + newTop + 'vh, 0)',
                    '-webkit-transition' : 'all ' + timeTrans + 'ms ease',
                    '-moz-transform' : 'translate3d(0, ' + newTop + 'vh, 0)',
                    '-moz-transition' : 'all ' + timeTrans + 'ms ease',
                    '-ms-transform' : 'translate3d(0, ' + newTop + 'vh, 0)',
                    '-ms-transition' : 'all ' + timeTrans + 'ms ease',
                    'transform' : 'translate3d(0, ' + newTop + 'vh, 0)',
                    'transition' : 'all ' + timeTrans + 'ms ease'
                });

                // New actual section
                const $section = this.sections.eq( newPage - 1 );
                
                // Section classes
                $section.addClass( 'coming actual visited' );

                // Nav color
                this.checkNavOptions( $section, newPage );

                // Check menu item
                this.pager.$items
                    .removeClass( 'active' )
                    .eq( newPage - 1 )
                    .addClass( 'active' )
                ;

                // Header class
                if( newPage === 1 ){
                    this.$header.removeClass( 'scrolled' );
                }
                else {
                    this.$header.addClass( 'scrolled' );
                }

                // Keep actual index
                this.actual = newPage;

                // Set hash
                if( setHash !== false ){
                    this.setHash( newPage );
                }

                // Time to set free
                if( this.timeSetFree ) clearTimeout( this.timeSetFree );
                this.timeSetFree = setTimeout( () => {
                    this.locked = false;
                }, this.transition * 0.7 );


                // Wait animation time
                if( this.timeout ) clearTimeout( this.timeout );
                this.timeout = setTimeout( () => {

                     this.$main.css({
                        '-webkit-will-change' : '',
                        '-moz-will-change' : '',
                        '-ms-will-change' : '',
                        'will-change' : '',
                    });

                    this.sections
                        .removeClass( 'going' )
                        .eq( this.actual )
                        .removeClass( 'coming' )
                    ;
                
                }, this.transition );

            }

        },

        setHash : function( index ){
            const 
                section = this.sections.eq( index - 1 ),
                hash = section.data( 'id' )
            ;
            window.location.hash = hash;
        },

        checkHash : function(){

            if( document.location.hash === '' || document.location.hash === '#' ){

                $section = this.sections.first();
                $section.addClass( 'coming actual visited' );
                this.checkNavOptions( $section, 1 );
            
            }
            else {

                const sectionID = document.location.hash.toString().substr( 1 );
                const target = this.sections.filter( '[data-id="' + sectionID + '"]' );
                if( target.length ){
                    
                    const index = target.index() + 1;
                    this.moveTo( index, 400, false );
                
                }

            }
            
        },
        
        checkNavOptions : function( $section, indice ){

            // Color
            if( $section.hasClass( 'navpage--black' ) ){
                this.pager.$.addClass( 'color-black' );
            }
            else {
                this.pager.$.removeClass( 'color-black' );
            }
        
            // Position
            if( indice === 1 ){
                this.pager.$.addClass( 'in-first-section' );
            }
            else {
                this.pager.$.removeClass( 'in-first-section' );
            }

        },

        getWindowHeight : function(){
            return 'innerHeight' in win ? win.innerHeight : doc.documentElement.offsetHeight;
        },

        getScrollTop : function(){
            var docElement = doc.documentElement;
            return (win.pageYOffset || docElement.scrollTop) - (docElement.clientTop || 0);
        }


    };

})(jQuery);
