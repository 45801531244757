var Utils = {

	vWidth : function vWidth(){
	    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
	},

	isMobile : function(){
	    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
	}

};