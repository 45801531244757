var 
	snapedPage,
	_body,
	_window
;

(function($){

	snapedPage = {

		$navs : null,
		$menu : null,
		$toggle : null,
		$header : false,
		timeout : false,
		$triggers : null,
		targets : {},
		totalTargets : 0,
		lastScollTop : 0,
		offseLimit : true,
		timeResize : false,
		timeClose : 1000,
		fixedScroll : 0,
		lockedScroll : false,
		scrollBinded : false,
		counter: 1,

		components : [],

		init : function(){
			
			// Just has class
			if( !$( 'html' ).hasClass( 'snaped-page-nav' ) ) return true;

			// Body
			if( _body === undefined ){
				_body = $( 'body' );
			} 

			// Window
			if( _window === undefined ){
				_window = $( window );
			}

			_body.children( 'main' ).children( 'section' ).first().addClass( 'visited' );

			/*
	    	// Check when resize
	    	$( window ).resize(function(){
	    		// Time resizing
	    		if( snapedPage.timeResize ) clearTimeout( snapedPage.timeResize );
	    		// Start timer
	    		snapedPage.timeResize = setTimeout(function(){
	    			// Check items
					snapedPage.checkItems();
	    		}, 100 );
	    	});
			snapedPage.checkItems();
			*/

		},

		isLockedScroll : function(){
			return snapedPage.lockedScroll === true;
		},

		lockScroll : function(){
			snapedPage.lockedScroll = true;
			snapedPage.fixedScroll = snapedPage.getPageTop();
			$( 'html' ).addClass( 'locked-scroll' );
		},
		
		unlockScroll : function(){
			snapedPage.lockedScroll = false;
			$( 'html' ).removeClass( 'locked-scroll' );
		},

	    vWidth : function vWidth(){
	        return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
	    },

	    vHeight : function vHeight(){
	        return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
	    },

	    getPageTop : function(){
	    	var top = ( window.pageYOffset || document.documentElement.scrollTop )  - ( document.documentElement.clientTop || 0 );
	    	return top;
	    },

		getPageHeight : function(){
		    var doc = document;
		    return  Math.max(
		        doc.body.scrollHeight, doc.documentElement.scrollHeight,
		        doc.body.offsetHeight, doc.documentElement.offsetHeight,
		        doc.body.clientHeight, doc.documentElement.clientHeight
		    );
		},

		getScrollInfo : function(){

			var 
				pageHeight = this.getPageHeight(),
				pageTop = ( window.pageYOffset || document.documentElement.scrollTop )  - ( document.documentElement.clientTop || 0 ),
				viweportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
				viweportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
				realPercuse = Math.ceil( pageTop + viweportHeight )
			;

			return {
				pageHeight : pageHeight,
				pageTop : pageTop,
				viweportHeight : viweportHeight,
				realPercuse : realPercuse,
				viweportWidth : viweportWidth
			};
		
		},

		checkIfBottom : function(){

			var scrlInfo = snapedPage.getScrollInfo();
			return scrlInfo.realPercuse >= scrlInfo.pageHeight;

		},

		isSafari : function(){
			var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
			return isSafari;
		},

		// Create menu nav
		checkItems : function(){

			// snapedPages
			this.$contents = $( 'body > main' ).children( '[id]' );
			if( !this.$contents.length ) return true;

			// Clearing
			snapedPage.targets = [];
			snapedPage.positions = [];
			snapedPage.totalTargets = 0;
			snapedPage.$contents.each(function(){
				
				this.$ = this.$ || $( this );

				// Get top position
				var top_position = parseInt( this.$.offset().top );
				if( top_position < 0 ) top_position = 0;

				// Insert in targets array
				if( !snapedPage.positions.includes( top_position ) ){
					snapedPage.positions.push( top_position );
				}

				// Insert in targets array
				var key_atgts = 'tgts-' + top_position;
				if( snapedPage.targets[ key_atgts ] === undefined ){
					snapedPage.targets[ key_atgts ] = [];
				}
				snapedPage.targets[ key_atgts ].push( this.$ );

				// Increment total
				snapedPage.totalTargets++;

			});

    		if( snapedPage.totalTargets > 0 ){
    	
	            // Ordering positions
    			snapedPage.positions = snapedPage.positions.sort(function( a, b ){ return a - b });    		

		    	// Binding scroll
	            snapedPage.checkScroll( true );
	            _window.bind( 'scroll', function(){
	                snapedPage.checkScroll();
	            });

    		}
    		
		},

	    checkScroll : function( initing ){

	    	// Top page positioun
	    	var scrollTop = snapedPage.getPageTop();

	    	// Direction
            var dir = scrollTop >= snapedPage.lastScrolTop ? 'down' : 'up';

			// Start calculate
	    	var 
	    		start = snapedPage.positions[ 0 ],
	    		items_key = false
	    	;

	    	// At top
	    	if( scrollTop <= start ){
	    		items_key = start;
	    	}
	    	// At bottom
	    	else if( snapedPage.checkIfBottom() ){
				items_key = snapedPage.positions[ snapedPage.positions.length - 1 ];
	    	}
	    	// Calculate positions
	    	else {

	    		// Offset
	    		var top_compare = scrollTop;

	    		// Section scroll offset
	    		var _offset_css = snapedPage.$contents.first().css( 'scroll-margin-top' );
	    		_offset_css = _offset_css === undefined ? 0 : parseInt( _offset_css );
	    		
	    		if( snapedPage.offseLimit ){
	    			// Scroll margin offset
		    		if( _offset_css > 0 && start > 0 ){
		    			top_compare -= _offset_css;
		    		}
	    			// Others browsers
	    			if( !snapedPage.isSafari() ){
		    			var _offset = Math.max( document.documentElement.clientHeight || 0, window.innerHeight || 0 ) / 4;

		    			if( dir === 'down' ){
		    				top_compare += _offset;
		    			}
		    			else {
		    				top_compare += _offset * 2;
		    			}
	    			}
	    			else {
						top_compare += _offset_css;
	    			}
	    		}
	    	
	    		// Calculate
	    		snapedPage.positions.forEach(function( position ){
		    		if( top_compare >= ( parseInt( position ) - start ) ){			    	
		    		   	items_key = position;
		    		}
	    		});
	    	
	    	}

	    	// Marking classes
	    	var targets_keys = Object.keys( snapedPage.targets );

	    	// Mark items
	    	var key_atgts = 'tgts-' + items_key;

	    	// Check and unchek
	    	targets_keys.forEach(function( tgtKey ){
	    		if( snapedPage.targets[ tgtKey ] !== undefined ){
					// Showing
	    			if( tgtKey === key_atgts ){
						snapedPage.targets[ tgtKey ].forEach(function( target ){
							// Once
							if( !target.hasClass( 'animated' ) ){
								target.addClass( 'animated' );
							}
							if( !target.hasClass( 'active' ) ){
					    		target.trigger( 'showing' );
								target.addClass( 'active' );
							}
			    		});
	    			}
	    			// Hidding
	    			else {
						snapedPage.targets[ tgtKey ].forEach(function( target ){
							if( target.hasClass( 'active' ) ){
					    		target.trigger( 'hiding' );
								target.removeClass( 'active' );
							}
			    		});
	    			}
		    	}
	    	});

			snapedPage.lastScrolTop = scrollTop;

	    },

	    scrollTo : function( selector, callback ){

	        var $target = typeof( selector ) === 'object' ? selector : $( selector );            
	        if( !$target.length ) return;

			var newY;
	        if( $target.attr( 'id' ) === 'inicio' ){
	        	newY = 0;
	        }
	        else {
		        newY = $target.offset().top;
	        }

	        var actualY = _window.scrollTop();
	        var distance = actualY - newY;

	        if( distance < 0 ) distance *= -1;
	        
	        var time = ( 600 - ( distance * 0.1 ) );
	        if( time < 600 ) time = 600;
	        
	        var offset = $target.data( 'offset' );
	        if( offset ){

	            try {

	                var 
	                    _json = JSON.parse( offset.split( "'" ).join( '"' ) )
	                    _offset = false,
	                    _vWidth = snapedPage.vWidth()
	                ;

	                $.each( _json.breaks, function( _break ){
	                    if( _vWidth <= _break ){
	                        _offset = parseInt( this );
	                        return false;
	                    }
	                });

	                offset = ( !_offset ) ? parseInt( _json.initial ) : _offset;

	            }
	            catch( e ){

	                var _offset = parseInt( offset );
	                offset = _offset > 0 ? _offset : 0;
	            
	            }

	        }
	        else {

	        	var vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
	        	offset = vw >= 992 ? 62 : 55;
	        
	        }

	        window.scrollTo( 0, ( newY - ( offset ) ) );

	    }
		
	};

	$(function(){
		//$( 'body' ).imagesLoaded(function(){
			snapedPage.init();
		//});
	});

})(jQuery);

