(function($){

	var Videos = {

		init : function(){

			$( 'body' ).on( 'click', '.video-container', function(e){

				this.$ = this.$ || $( this );
				if( this.$.hasClass( 'ready' ) ) return true;

				this.$.addClass( 'ready' );
				this.$.$video = this.$.$video || this.$.find(  'iframe' );
				this.$.$video.attr( 'src', this.$.$video.data( 'source' ) );

			});

		}

	};

	$(function(){
		Videos.init();
	});

})(jQuery);	
