var ListLinks;

(function($){

	ListLinks = {

		$ : null,

		init : function(){

			this.$ = $( '.list-links' );
			if( !this.$.length ) return true;

            var data =  {
                $ : this.$,
                $items : $( '.years-list .year', this.$ ),
                $controls : $( '.tabs-controls', this.$ ),
                $dots : $( '.dots', this.$ ),
                year : null
            };
            data.$tabs = $( 'ul button', data.$controls );
            data.totalTabs = data.$tabs.length;
            data.$arrows = $( '.arrows button', data.$controls );
            data.$arrowPrev = $( '.arrows button.prev', data.$controls );
            data.$arrowNext = $( '.arrows button.next', data.$controls );
            data.year = data.$tabs.first().text();

            data.$arrows.on( 'click', function(){

                this.$ = this.$ || $( this );
                if( this.$.prop( 'disabled' ) ) return true;

                var 
                    sentido = this.$.hasClass( 'prev' ) ? 'prev' : 'next',
                    $active = data.$tabs.filter( '.active' ),
                    $toClick = false,
                    activeIndex = $active.parent().index(),
                    newIndex
                ;

                if( activeIndex === 0 ){
                    newIndex = sentido === 'next' ? activeIndex + 1 : ( data.totalTabs - 1 );
                }
                else if( activeIndex === ( data.totalTabs - 1 ) ){
                    newIndex = sentido === 'prev' ? activeIndex - 1 : 0;
                }
                else {
                    newIndex = sentido === 'next' ? ( activeIndex + 1 ) : ( activeIndex - 1 );
                }

                // Disable before
                data.$items.removeClass( 'active' );
                data.$tabs.removeClass( 'active' );

                // Enable
                data.$items.eq( newIndex ).addClass( 'active' );
                data.$tabs.eq( newIndex ).addClass( 'active' );

                ListLinks.checkArrows( data );
                
            });

		},

        checkArrows : function( data ){
            var 
                $active = data.$tabs.filter( '.active' ),
                activeIndex = $active.parent().index()
            ;
            if( activeIndex === 0 ){
                data.$arrowPrev.prop( 'disabled', true );
            }
            else {
                data.$arrowPrev.prop( 'disabled', false );
            }
            
            if( activeIndex === ( data.totalTabs - 1 ) ){
                data.$arrowNext.prop( 'disabled', true );
            }
            else {
                data.$arrowNext.prop( 'disabled', false );
            }
        }

	};

	$(function(){
		ListLinks.init();
	})

})(jQuery);	
