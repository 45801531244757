var ModalVideo;

(function($){

    ModalVideo = {

        $items : false,
        initedResize : false,
        timeResize : false,
        timeAPI : false,

        init : function(){

            // Full vídeo
            ModalVideo.$items = $( 'div.video-modal-open' );
            if( ModalVideo.$items.length ){
                // Initing
                ModalVideo.$items.each(function(){
                    ModalVideo.configure( this );
                });
            }

            $(window).on( 'click', function(e){
                e.target.$ = e.target.$ || $( e.target );

                console.info( "e.target.$" );
                console.log( e.target.$ );
                
                if( 
                    !e.target.$.is( '[data-action="play-video"]' )
                    &&
                    !e.target.$.parents( '.video-modal-open' ).length 
                    && 
                    !e.target.$.parents( '.video-wrapper' ).length 
                ){
                    ModalVideo.closeVideos();
                }
            });
        
        },

        closeVideos : function(){
            
            ModalVideo.$items.each(function(){
                this.$ = this.$ || $( this );
                var data = this.$.data( 'ModalVideo' );
                ModalVideo.closeModal( data );
            });

        },

        configure : function( item ){

            item.$ = item.$ || $( item );
            
            var data = item.$.data( 'ModalVideo' );
            if( data !== undefined ) return data;

            data = {
                $ : item.$,
                $trigger : $( '[data-action="play-video"]' ),
                $modal : $( '.box-modal-video' ),
                $close : $( '[data-action="close-video"]' ),
                player: false
            };
            data.$video = $( 'iframe', data.$modal );
            data.videocode = data.$video.data( 'video-code' );
            data.videoID = 'video-encerramento';

            // Keep data
            item.$.data( 'ModalVideo', data );

            data.$trigger.on( 'click', function(e){
                e.preventDefault();
                ModalVideo.openModal( data );
            });
            data.$close.on( 'click', function(e){
                e.preventDefault();
                ModalVideo.closeModal( data );
            });

            // Init Vídeo
            this.APIWait(function(){
                // Inicia o vídeo
                data.player = new YT.Player(
                    'video-encerramento', 
                    {
                        width: '100%',
                        height: '100%',
                        videoId: 'Pi876XiI5ik',
                        playerVars: { 
                            autoplay : false, 
                            showinfo : false, 
                            rel : false 
                        },
                        events: {
                            'onStateChange': function( e ){
                                ModalVideo.onPlayerStateChange( e, data );
                            }
                        }
                    }
                );
            });

        },
        
        onPlayerStateChange : function( event, data ){


            if( event.data == YT.PlayerState.ENDED ){
                ModalVideo.closeModal( data );
                data.player.seekTo( 0 );
            }
            else if( YT.PlayerState.PLAYING === event.data ){

                if( !data.$modal.hasClass( 'opened' ) ){
                    ModalVideo.openModal( data );
                }
                
            }
        
        },

        openModal : function( data ){

            data.$modal.addClass( 'opened' );

            if( data.player && data.player.playVideo !== undefined ){
                data.player.playVideo();
            }
        
        },

        closeModal : function( data ){
            
            data.$modal.removeClass( 'opened' );
            
            if( data.player && data.player.pauseVideo !== undefined ){
                data.player.pauseVideo();
            }
        
        },

        // Waiting youtube API
        APIWait : function( callback ){
            if( typeof( YT ) === 'undefined' || typeof( YT.Player ) === 'undefined' ){
                if( ModalVideo.timeAPI ) clearTimeout( ModalVideo.timeAPI );
                ModalVideo.timeAPI = setTimeout(function(){
                    ModalVideo.APIWait( callback );
                }, 50);
            }
            else {
                clearTimeout( ModalVideo.timeAPI );
                callback();
            }
        }

    };
    $(function(){
        ModalVideo.init();
    })

})(jQuery);
